import { localizedMonths } from "./months"
import { PageContext } from "ExtendiApp"

export const formatDateToShow = (date: string, locale: PageContext["locale"]) => {
  const dateArray = date.includes("/") ? date.split("/") : date.split(".")
  const monthIndex = parseInt(dateArray[1])
  const shortMonth = localizedMonths[locale][monthIndex - 1]
  return dateArray.length > 2 ? `${dateArray[0]} ${shortMonth.substr(0, 3)}` : ""
}

const actualMonth = new Date().getMonth()

const currentYear = new Date().getFullYear()

// Change year at the end of september
const year = actualMonth <= 9 ? currentYear : currentYear + 1
export const openingDate = new Date(`05/29/${year}`) // apre il 29 maggio
export const closingDate = new Date(`09/14/${year}`) // chuiude il 14 settembre
